import './Home.css';
import Restaurant from "../../components/home/restaurant/Restaurant";
import Box from "@mui/material/Box";
import Carta from "../../components/home/carta/Carta";
import Historia from "../../components/home/historia/Historia";
import CarouselBanner from "../../components/home/carouselBanner/CarouselBanner";
import Footer from "../../components/core/footer/Footer";

function Home() {
  return (
    <>
        <Box sx={{ marginTop: '124px' }}>
            <CarouselBanner />
        </Box>
        <Restaurant />
        <Carta />
        <Historia />
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2986.8850984584533!2d2.432248815274747!3d41.528429495202786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4b4e449e4b9ff%3A0xafa5c65d7d90134a!2sTecnocampus%20TMC1!5e0!3m2!1ses!2ses!4v1674753671588!5m2!1ses!2ses"
            allowFullScreen=""
            loading="lazy"
            width="100%"
            height="500"
            style={{border: 'none', marginTop: '60px'}}
            referrerPolicy="no-referrer-when-downgrade"
            title="location"
        />
        <Footer />
    </>
  );
}

export default Home;
