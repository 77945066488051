import {withTranslation} from "react-i18next";
import {Container, Grid} from "@mui/material";
import Contact from "../../components/reserva/form/Contact";
import Schedule from "../../components/reserva/schedule/Schedule";

function Reserva ({ t }) {

    return (
        <Container sx={{ marginTop: '124px' }}>
                <Grid container spacing={2} marginBottom={4}>
                    <Grid item xs={12} md={6}>
                        <h2>{t('title')}</h2>
                        <Contact />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h2>Nuestro horario</h2>
                        <Schedule />
                    </Grid>
                </Grid>
        </Container>
    )
}

export default withTranslation('reserva')(Reserva);
