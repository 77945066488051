import List from "@mui/material/List";
import {ListItem} from "@mui/material";

function Schedule () {

    return (
        <List>
            <ListItem>
                Lunes: 13:00 - 15:00
            </ListItem>
            <ListItem>
                Martes: Cerrado
            </ListItem>
            <ListItem>
                Miercoles: 13:00 - 15:00
            </ListItem>
            <ListItem>
                Jueves: 13:00 - 15:00
            </ListItem>
            <ListItem>
                Viernes: 13:00 - 15:00
            </ListItem>
            <ListItem>
                Sábado: 13:00 - 15:00
            </ListItem>
            <ListItem>
                Domingo: 13:00 - 15:00
            </ListItem>
        </List>
    )
}

export default Schedule;
