import Banner from "../../shared/banner/Banner";

function CarouselBanner() {
    const items = [
        {
            img: "/img/b-1.jpg"
        },
        {
            img: "/img/b-2.jpg"
        },
        {
            img: "/img/b-3.jpg"
        }
    ];

    return(<Banner items={items} />)
}

export default CarouselBanner;
