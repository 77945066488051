import Carousel from "react-material-ui-carousel"
import BannerItem from "./BannerItem";
import Box from "@mui/material/Box";
import "./Banner.css";

function Banner({items}) {

    return (
        <Box>
            <Carousel
                animation="slide"
                changeOnFirstRender={false}
                cycleNavigation={true}
                fullHeightHover={true}
                autoPlay={true}
                duration={500}
                navButtonsAlwaysVisible
                indicatorContainerProps={{
                    style: {
                        position: "absolute",
                        bottom: "10px",
                        zIndex: "5"
                    }
                }}
                sx={{aspectRatio: '2.6'}}
            >
                {
                    items.map( (item, i) => <BannerItem key={i} item={item} /> )
                }
            </Carousel>
        </Box>
    )
}

export default Banner;
