import {Controller, useForm} from "react-hook-form";
import { useState } from "react";
import {Grid, TextField, MenuItem, Button, CircularProgress, Alert} from '@mui/material';
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import * as React from "react";
import SimpleDialog from "./SimpleDialog";

const people = [1,2,3,4,5,6,7,8,9,10,11,12]
function Contact () {
    const { register, formState: { errors }, handleSubmit, control, setValue, getValues, clearErrors, reset } = useForm(
        {
            defaultValues: {
                day: moment(), // Si no volem possar cap dia: null
                time: ""
            }
        }
    );
    const [open, setOpen] = React.useState(false);
    const [okey, setOkey] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    console.log(errors)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setValue( 'time', value );
        clearErrors('time');
    };

    const onSubmit = (value) => {
        setIsLoading(true)
        fetch("https://formsubmit.co/ajax/194010a1ee79f2ff1aebdf2a0750e3d9",
            {
                method: 'POST',
                body: JSON.stringify(value),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoading(false);
                    setOkey('Muchas gracias, en confirmar su reserva le avisaremos por email.');
                    reset(); // Hacer el reset del formulario
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoading(false);
                    setError('¡Se ha producido un error! Por favor inténtalo de nuevo más tarde.');
                }
            )
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
                label="Name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors?.name?.message}
                color="primary"
                fullWidth
                sx={{ marginBottom: '16px' }}
                inputProps={register("name", {
                    required: {
                        value: true,
                        message: "Name is required"
                    },
                    maxLength: {
                        value: 30,
                        message: "Name is to long"
                    }
                })}
            />
            <TextField
                label="Email"
                variant="outlined"
                error={!!errors.email}
                helperText={errors?.email?.message}
                color="primary"
                fullWidth
                sx={{ marginBottom: '16px' }}
                inputProps={register("email", {
                    required: {
                        value: true,
                        message: "Email is required"
                    },
                    pattern: {
                        value: /^\S+@\S+$/i,
                        message: "Email invalid"
                    }
                })}
            />
            <TextField
                label="Phone"
                variant="outlined"
                error={!!errors.phone}
                helperText={errors?.phone?.message}
                color="primary"
                fullWidth
                inputProps={register("phone", {
                    required: {
                        value: true,
                        message: "Phone is required"
                    },
                    minLength: {
                        value: 6,
                        message: "Invalid Phone"
                    },
                    maxLength: {
                        value: 12,
                        message: "Invalid Phone"
                    },
                })}
            />
            <Grid container spacing={1} marginTop={1} marginBottom={1}>
                <Grid item xs={4}>
                    <TextField
                        label="People"
                        select
                        fullWidth
                        defaultValue=''
                        error={!!errors.people}
                        helperText={errors?.people?.message}
                        inputProps={register("people", {
                            required: {
                                value: true,
                                message: "People required"
                            }
                        })}
                    >
                        {people.map((x) =>
                            <MenuItem key={x} value={x}>
                                {x}
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Controller
                            name="day"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Date required"
                                }
                            }}
                            render={({
                                 field: { onChange, value },
                                 fieldState: { error, invalid }
                            }) => (
                                <MobileDatePicker
                                    label="Date"
                                    disablePast
                                    inputFormat="DD-MM-YYYY"
                                    value={value}
                                    onChange={(value) =>
                                        onChange(moment(value))
                                    }
                                    renderInput={(params) =>
                                        <TextField {...params} error={!!errors.day} helperText={errors?.day?.message} fullWidth />
                                    }
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        value={getValues('time')}
                        label="Hora"
                        variant="outlined"
                        onClick={handleClickOpen}
                        error={!!errors.time}
                        helperText={errors?.time?.message}
                        InputProps={
                            register("time",{
                                required: {
                                    value: true,
                                    message: "Time required"
                                }
                            })
                        }
                    />
                </Grid>
            </Grid>
            <SimpleDialog
                selectedValue={getValues('time')}
                open={open}
                onClose={handleClose}
            />
            <Button variant="contained" type="submit" fullWidth size="large" disabled={isLoading} sx={{marginTop: '8px'}}>
                Enviar
                {
                    isLoading && (<CircularProgress sx={{marginLeft: '16px'}} size={20} />)
                }
            </Button>
            <div>
                {
                    okey && (<Alert sx={{ marginTop: '16px' }} severity="success">{okey}</Alert>)
                }
            </div>
            <div>
                {
                    error && (<Alert sx={{ marginTop: '16px' }} severity="error">{error}</Alert>)
                }
            </div>
        </form>
    );
}

export default Contact;
