import * as React from 'react';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Button, Grid} from "@mui/material";

const time = ['13:00', '13:15', '13:30', '13:45', '14:00'];

function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Select the time</DialogTitle>
            <List sx={{ pt: 0 }}>
                <Grid container spacing={1} padding={3}>
                    {time.map((t) => (
                        <Grid item xs={4} key={t}>
                            <Button fullWidth variant="outlined" onClick={() => handleListItemClick(t)}>
                                {t}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </List>
        </Dialog>
    );
}

export default SimpleDialog;
