import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from "@mui/material";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";

import './index.css';
import './i18n';
import Home from "./pages/home/Home";
import CssBaseline from "@mui/material/CssBaseline";
import AppLayout from "./layout/AppLayout";
import Reserva from "./pages/reserva/Reserva";

const DEFAULT_THEME = 'light';
const getDesignPalette = (mode) => ({
    palette: {
        mode,
        ...(mode === DEFAULT_THEME
            ? {
                // palette values for light mode
                primary: {
                    main: '#0a95ff'
                },
                secondary: {
                    main: '#ffffff'
                },
            }
            : {
                // palette values for dark mode
                primary: {
                    main: '#0a95ff',
                },
                secondary: {
                    main: '#000000',
                },
            }),
    },
});

export const ThemeContext = React.createContext({
    theme: DEFAULT_THEME,
    toggleTheme: () => {},
});

const router = createBrowserRouter([
    {
        path: '/',
        element: <Suspense><AppLayout /></Suspense>,
        children: [
            {
                index: true,
                element: <Home/>,
            },
            {
                path: "reservas",
                element: <Reserva />,
            }
        ]
    },
    {
        path: '*',
        element: <Navigate to="/" />
    }
]);

function App() {
    const [theme, setTheme] = React.useState(DEFAULT_THEME);
    const toggleTheme = () => (setTheme(theme === DEFAULT_THEME ? 'dark' : DEFAULT_THEME))

    return(
        <ThemeContext.Provider value={
            {
                theme,
                toggleTheme
            }
        }>
            <ThemeProvider theme={createTheme(getDesignPalette(theme))}>
                <CssBaseline />
                <React.StrictMode>
                    <RouterProvider router={router} />
                </React.StrictMode>
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
