import Box from "@mui/material/Box";
import {Card, CardContent, Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import './Carta.css';

function Carta() {
    return (
        <Box sx={{ background: '#f4ece5', position: 'relative', overflow: 'hidden' }} id="carta">
            <Box className="fish">
                <img src="/img/p-1.png" width="716" className="fish-1"  alt="" />
                <img src="/img/p-2.png" width="559" className="fish-2"  alt="" />
                <img src="/img/p-3.png" width="913" className="fish-3"  alt="" />
            </Box>
            <Container maxWidth="lg" className="menu">
                <Typography variant="h2" align="center" paddingTop={6} paddingBottom={6}>
                    La Carte
                </Typography>
                <Grid container alignContent="center" justifyContent="center" paddingBottom={8}>
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h3" align="center">
                                    Menu de la mer
                                </Typography>
                                <p>SERVI SEULEMENT AU DÉJEÛNER</p>
                                <p>55€</p>
                                <p>Thon germon en tartare , concombre et coriandre ou Sardine en beignet , tomate et menthe</p>
                                <p>Lotte tiède aux herbes d’été ou palourdes à l’ail</p>
                                <p>Gâteau au chocolat ou salade d’agrumes</p>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Carta;
