import NavBar from "../components/core/navbar/NavBar";
import { Outlet } from "react-router-dom";

function AppLayout () {
    return (
        <>
            <NavBar />
            <div>
                <Outlet />
            </div>
        </>
    )
}

export default AppLayout;
