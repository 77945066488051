import { Button } from "@mui/material";
import { ThemeContext } from "../../../index";

function Footer() {

    return(
        <>
            Footer
            <ThemeContext.Consumer>
                {({theme, toggleTheme}) => (
                    <Button
                        onClick={toggleTheme}
                    >
                        Toggle Theme { theme }
                    </Button>
                )}
            </ThemeContext.Consumer>
        </>
    )
}

export default Footer;
